const TEAM =  [
  {
    name: 'ITB',
    image: 'itb.png',
    role: 'Institut Teknologi Bandung',
  },
  {
    name: 'UI',
    image: 'ui.png',
    role: 'Universitas Indonesia',
  },
  {
    name: 'ITS',
    image: 'its.png',
    role: 'Institut Teknologi Sepuluh Nopember',
  },
  {
    name: 'UNTAN',
    image: 'untan.png',
    role: 'Universitas Tanjungpura',
  },
  {
    name: 'UNSRI',
    image: 'unsri.png',
    role: 'Universitas Sriwijaya',
  },
  {
    name: 'UNHAS',
    image: 'unhas.png',
    role: 'Universitas Hasanudin',
  },
  {
    name: 'UNDIP',
    image: 'undip.png',
    role: 'Universitas Diponegoro',
  },
  {
    name: 'Tel-U',
    image: 'tel-u.png',
    role: 'Universitas Telkom',
  },
  {
    name: 'UNS',
    image: 'uns.png',
    role: 'Universitas Sebelas Maret',
  },
  {
    name: 'ITERA',
    image: 'itera.png',
    role: 'Institut Teknologi Sumatera',
  },
  {
    name: 'UB',
    image: 'ub.png',
    role: 'Universitas Brawijaya',
  },
  {
    name: 'UGM',
    image: 'ugm.png',
    role: 'Universitas Gadjah Mada',
  },
];

export default TEAM;