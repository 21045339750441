import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Container, TitleLink } from '@components/global';
import { TeamGrid, Title, Subtitle } from './style';
import TEAM from './content';

const Team = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="team">
        <Container>
          <h2>Tim Kami</h2>
          <TeamGrid>
            {TEAM.map(({ name, image, role }) => {
              const img = data.allFile.edges.find(
                ({ node }) => node.relativePath === image
              ).node;

              return (
                <TitleLink to={name.toLowerCase()} key={name}>
                  <div>
                    <Img fluid={img.childImageSharp.fluid} alt={name} />
                    <Title>{name}</Title>
                    <Subtitle>{role}</Subtitle>
                  </div>
                </TitleLink>
              );
            })}
          </TeamGrid>
        </Container>
      </Section>
    )}
  />
);



export default Team;
