import React from 'react';
import { Layout } from '@common';

import AllTeam from '@sections/Team/AllTeam';

const teams = () => {
  return (
    <Layout page="Tim" includeHeading={false}>
      <AllTeam />
    </Layout>
  );
};

export default teams;
